import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PlayCircleIcon, UserIcon } from "@heroicons/react/24/solid";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { apiRequest } from "../../config/ApiConfig";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { DynamicToggle } from "../../components/Toggle";
import { ApplicantAttachments } from "./ApplicantAttachments";
import { FileUpload } from "../../components/FileUpload";
import { Attachment } from "../../components/Attachment";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { Notification } from "../../components/Notification";
import { AssessmentReports } from "./AssessmentReports";
import AssessmentReport from "../../components/AssessmentReport";
import {
  classNames,
  copyObject,
  formatAsLocalDate,
  formatAsLocalDateTime,
  formatTime,
  isEmptyJson,
  isNotBlank
} from "../../utils/uiUtils";
import {
  assessmentStatus,
  assessmentStatusColor,
  parenthesizeIfNotEmpty
} from "../ProcessUtils";
import {
  continueTestRequest,
  deleteEvaluationReport,
  getProcessApplicantStatus,
  refreshCandidateStatus,
  updateApplicantAssessmentStatus
} from "../../api";
import unionWith from "lodash.unionwith";

export const ApplicantStatusCard = ({
  processId,
  processState,
  applicantId,
  applicantName
}) => {
  const { t } = useTranslation();
  const [applicantLoading, setApplicantLoading] = useState(true);
  const [applicantRefreshing, setApplicantRefreshing] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [ongoingRemove, setOngoingRemove] = useState(false);
  const [removalError, setRemovalError] = useState(false);
  const [applicant, setApplicant] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationContent, setNotificationContent] = useState("");

  const reportUri = `${apiRequest.apiProcesses.processesAdminEndpoint}/${processId}/applicant/${applicantId}/evaluationReport`;
  const assessmentReportUri = `${apiRequest.apiProcesses.processesAdminEndpoint}/${processId}/applicant/${applicantId}/assessmentReport`;
  const refreshSvgCss = applicantRefreshing
    ? "h-5 w-5 animate-spin"
    : "h-5 w-5";

  const updateAvailable = processState === "Published";

  useEffect(() => {
    const fetchData = async () => {
      await getProcessApplicantStatus(processId, applicantId)
        .then((response) => {
          setApplicant(response);
        })
        .catch((error) => {
          console.log(error);
          setApplicant({});
        })
        .finally(() => {
          setApplicantLoading(false);
        });
    };
    if (applicantLoading) {
      fetchData().catch(console.error);
    }
  }, [processId, applicantId, applicant, applicantLoading]);

  const updateAssessment = async (assessmentId, value) => {
    const originalApplicantProcess = copyObject(applicant);
    const updatedAssessments = applicant.assessments.map((a) =>
      a.id === assessmentId
        ? { ...a, status: value ? "Completed" : "NotStarted" }
        : a
    );

    setApplicant({ ...applicant, assessments: updatedAssessments });

    const response = await updateApplicantAssessmentStatus(
      processId,
      assessmentId,
      applicant.candidateId,
      value
    );

    if (!response.id) {
      console.log("Error updating ProcessAssessment status: ", response);
      setApplicant({ ...applicant, assessments: originalApplicantProcess });
    }
  };

  const evaluationReportChanged = (data) => {
    setApplicant({ ...applicant, evaluationReport: data });
  };

  const assessmentReportChanged = (data) => {
    const updatedData = unionWith([data], applicant.assessmentReports, (item) => item.fileName === data.fileName);
    setApplicant({ ...applicant, assessmentReports: updatedData });
  };

  const assessmentReportRemoved = (reportId) => {
    let updatedData = applicant.assessmentReports.filter(item => item.id !== reportId);
    setApplicant({ ...applicant, assessmentReports: updatedData });
  };

  const removeEvaluationReport = async () => {
    setOngoingRemove(true);
    setRemovalError(false);
    const response = await deleteEvaluationReport(processId, applicantId).catch(
      (e) => {
        console.log(e);
        setRemovalError(true);
      },
    );
    if (response?.deleted === true) {
      setApplicant({ ...applicant, evaluationReport: {} });
      setRemovalError(false);
    } else {
      setRemovalError(true);
    }
    setShowConfirmDialog(false);
    setOngoingRemove(false);
  };

  const handleRefreshCandidate = async () => {
    setApplicantRefreshing(true);
    const response = await refreshCandidateStatus(processId, applicantId);

    if (response.candidateId === applicant.candidateId) {
      setApplicant(response);
    } else {
      console.log("Error refreshing candidate: ", response, applicant);
    }
    setApplicantRefreshing(false);
  };

  const handleContinueTest = async (assessmentId) => {
    if (applicantRefreshing || applicantLoading) {
      return;
    }
    setApplicantRefreshing(true);
    await continueTestRequest(processId, applicantId, assessmentId)
      .then((response) => {
        if (response.requestAccepted) {
          handleRefreshCandidate();
        } else {
          updateNotification(true, t("Errors.Title"), response.error);
        }
      })
      .catch((error) => {
        console.log(error);
        updateNotification(true, t("Errors.Title"), "");
      })
      .finally(() => {
        setApplicantRefreshing(false);
      });
  };

  const updateNotification = (isVisible, title, content) => {
    setNotificationTitle(title);
    setNotificationContent(content);
    setShowNotification(isVisible);
  };

  const closeNotification = () => {
    updateNotification(false, "", "");
  };

  return (
    <div>
      {applicantLoading ? (
        <div className="container mx-auto w-full divide-y">
          <div className="p-4">
            <p className="font-semibold text-xl mb-1 text-gray-900">
              {applicantName}
            </p>
          </div>
          <div className="p-4">
            <LoadingIndicator type="small" />{" "}
            <span className="pl-3">{t("Overlay.Loading")}</span>
          </div>
        </div>
      ) : (
        <div
          key={`applicant_${processId}_${applicantId}_key`}
          className="container mx-auto w-full divide-y"
        >
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div className="whitespace-nowrap flex content-start">
              <p className="font-semibold text-xl mb-1 text-gray-900">
                {applicantName}
              </p>

                <Link
                    to={`/candidates/${applicant.candidateId}`}
                    className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline pr-4"
                >
                  <UserIcon
                      className="ml-2 mt-1 h-5 w-5 flex-shrink-0 text-mps-ultramarine"
                      aria-hidden="true"
                      title={t("Candidates.ViewCandidate")}
                  />
                </Link>
              </div>
              { updateAvailable && (
                <button
                  onClick={handleRefreshCandidate}
                  className="ring-1 ring-gray-300 bg-inherit rounded-md p-1 -mt-3"
                >
                  <ArrowPathIcon className={refreshSvgCss} />
                </button>
              )}
            </div>
            {isNotBlank(applicant.lastLoginTime) ? (
              <div className="text-gray-700 text-sm mb-2">
                {t("Candidate.LastLoginTime")}:{" "}
                {formatAsLocalDateTime(applicant.lastLoginTime)}
              </div>
            ) : (
              <div className="text-gray-700 text-sm mb-2">
                {t("Candidate.NoLoginTimeAvailable")}
              </div>
            )}
            <div className="mb-2">{t("ProcessProgress.Interviews")}</div>
            <ul
              className="list-disc ml-4 text-gray-500"
              key={`applicant_interviews_list_${applicantId}_key`}
            >
              {applicant.individualInterviewType && (
                <li className="mb-2">
                  {t("ProcessProgress.IndividualInterview")}:{" "}
                  {applicant.individualInterviewDate ? (
                    <span className="text-gray-900">
                      {formatAsLocalDate(applicant.individualInterviewDate)} klo{" "}
                      {formatTime(applicant.individualInterviewTime)}
                    </span>
                  ) : (
                    <span className="text-red-500">
                      {t("ProcessProgress.InterviewNotScheduled")}
                    </span>
                  )}
                </li>
              )}
              {applicant.individualSimulationType &&
                applicant.individualSimulationType !== "NoInterview" && (
                  <li className="mb-2">
                    {t("ProcessProgress.IndividualSimulation")}:{" "}
                    {applicant.individualSimulationDate ? (
                      <span className="text-gray-900">
                        {formatAsLocalDate(applicant.individualSimulationDate)}{" "}
                        klo {formatTime(applicant.individualSimulationTime)}
                      </span>
                    ) : (
                      <span className="text-red-500">
                        {t("ProcessProgress.InterviewNotScheduled")}
                      </span>
                    )}
                  </li>
                )}
              {applicant.groupSimulationType &&
                applicant.groupSimulationType !== "NoInterview" && (
                  <li className="mb-2">
                    {t("ProcessProgress.GroupSimulation")}:{" "}
                    {applicant.groupSimulationDate ? (
                      <span className="text-gray-900">
                        {formatAsLocalDate(applicant.groupSimulationDate)} klo{" "}
                        {formatTime(applicant.groupSimulationTime)}
                      </span>
                    ) : (
                      <span className="text-red-500">
                        {t("ProcessProgress.GroupSimulationNotScheduled")}
                      </span>
                    )}
                  </li>
                )}
            </ul>
          </div>
          <div className="p-4">
            <div className="mb-2">{t("ProcessProgress.Assessments")}</div>
            <ul
              className="list-disc ml-4 text-gray-500"
              key={`applicant_assessments_list_${applicantId}_key`}
            >
              {applicant.assessments &&
                applicant.assessments.map((assessment) => (
                  <li
                    key={`applicant_assessment_key_${assessment.id}`}
                    className="mb-2"
                  >
                    <span className="">
                      {assessment.provider}: {assessment.name}{" "}
                      {parenthesizeIfNotEmpty(assessment.options)}:&nbsp;
                      <span
                        className={assessmentStatusColor(assessment.status)}
                      >
                        {t(assessmentStatus(assessment.status))}
                      </span>
                    </span>
                    {assessment.continueAvailable && (
                      <div className="lg:inline-flex text-left align-bottom">
                        <PlayCircleIcon
                          aria-hidden="true"
                          title={t("Actions.ContinueTest")}
                          onClick={() => handleContinueTest(assessment.id)}
                          className={classNames(
                            applicantRefreshing || applicantLoading
                              ? "text-gray-500"
                              : "text-green-500 hover:cursor-pointer",
                            "ml-2 mb-0.5 h-5 w-5 flex-shrink-0",
                          )}
                        />
                      </div>
                    )}
                    {!assessment.integrated && (
                      <div className="lg:inline-flex lg:float-right text-left pt-1">
                        <DynamicToggle
                          label={t("ProcessProgress.MarkAsCompleted")}
                          value={assessment.status === "Completed"}
                          action={(value) => {
                            updateAssessment(assessment.id, value);
                          }}
                        />
                      </div>
                    )}
                    {assessment.hideFromCandidate && assessment.status !== 'Completed' && (
                      <div className="text-sm font-medium mt-1 group flex items-start">
                        <DocumentPlusIcon
                          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5"
                          aria-hidden="true"
                        />
                        <a
                            href={assessment.linkUrl}
                            rel="noreferrer"
                            target={"_blank"}
                            className="text-mps-ultramarine underline mt-0.5 mb-1 ml-1"
                        >
                          {t("ProcessProgress.DoAssessment", { assessmentName: assessment.name.toLowerCase() })}
                        </a>
                      </div>
                    )}
                    {!isEmptyJson(assessment.reports) &&
                      assessment.reports.map((report) => (
                        <div key={`report_key_${crypto.randomUUID()}`}>
                          <AssessmentReport report={report} />
                        </div>
                      ))}
                  </li>
                ))}
            </ul>
          </div>
          <div className="p-4">
            <AssessmentReports
                title={t("ProcessProgress.AssessmentReport.Title")}
                processId={processId}
                applicantId={applicantId}
                assessmentReportUri={assessmentReportUri}
                assessmentReports={applicant.assessmentReports}
                assessmentReportChanged={assessmentReportChanged}
                assessmentReportRemoved={assessmentReportRemoved}
                updateAvailable={updateAvailable}
            />
          </div>
          <div className="p-4">
            <div className="mb-2">{t("ProcessProgress.ApplicantAttachments")}</div>
            <ApplicantAttachments attachments={applicant.attachments} />
          </div>
          <div className="p-4">
            <div className="mb-2">
              {t("ProcessProgress.EvaluationReport.Title")}
            </div>
            { updateAvailable && isEmptyJson(applicant.evaluationReport) ? (
              <FileUpload
                id={`applicant_${applicantId}_evaluation_report`}
                label={t("ProcessProgress.EvaluationReport.Add")}
                emptyDescription={t("ProcessProgress.EvaluationReport.NotAdded")}
                sizeLimit={10}
                uri={reportUri}
                fileType={"application/pdf"}
                attachmentType={"EvaluationReport"}
                action={evaluationReportChanged}
              />
            ) : !isEmptyJson(applicant.evaluationReport) ? (
              <div>
                <div className="text-sm mt-1 group flex items-start">
                  <Attachment
                    item={applicant.evaluationReport}
                    removeAction={() => setShowConfirmDialog(true)}
                    removeEnabled={updateAvailable}
                  />
                  <ConfirmDialog
                    title={t("ProcessProgress.EvaluationReport.Remove")}
                    content={t(
                      "ProcessProgress.EvaluationReport.ConfirmRemoval",
                    )}
                    confirmButtonLabel={t("Actions.Delete")}
                    open={showConfirmDialog}
                    setOpen={setShowConfirmDialog}
                    action={removeEvaluationReport}
                    ongoingAction={ongoingRemove}
                  />
                </div>
                {removalError && (
                  <p className="mt-2 text-sm text-red-600">
                    {t("ProcessProgress.EvaluationReport.RemoveFailed")}
                  </p>
                )}
              </div>
            ) : (
              <div className="text-sm mt-1 group flex items-start">
                {t("ProcessProgress.EvaluationReport.NotAdded")}
              </div>
            )}
          </div>
        </div>
      )}
      {/* Notification live region */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Notification
            title={notificationTitle}
            content={notificationContent}
            show={showNotification}
            action={closeNotification}
            isSuccess={false}
          />
        </div>
      </div>
    </div>
  );
};
