import React from "react";
import { PaperClipIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { DocumentCheckIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";

export const Attachment = ({ item, removeAction, removeEnabled = true }) => {
  return (
    <div className="text-sm font-medium mt-1 group flex items-start overflow-hidden">
      {item.attachmentType === "ProfileImage" ? (
        <UserCircleIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5 text-gray-600"
          aria-hidden="true"
        />
      ) : item.attachmentType === "EvaluationReport" ? (
        <DocumentCheckIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5 text-gray-800"
          aria-hidden="true"
        />
      ) : item.attachmentType === "AssessmentReport" ? (
        <DocumentTextIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5 text-gray-800"
          aria-hidden="true"
        />
      ) : (
        <PaperClipIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5 text-gray-600"
          aria-hidden="true"
        />
      )}

      <span className="truncate font-medium">
        <a
          href={item.uri}
          target={"_blank"}
          rel="noreferrer"
          className="text-mps-ultramarine underline mt-0.5 hover:underline"
        >
          {item.fileName}
        </a>
      </span>
      {removeAction && removeEnabled && (
        <span className="pl-4">
          <TrashIcon
            className="-ml-0.5 h-5 w-5 hover:cursor-pointer text-gray-600"
            aria-hidden="true"
            onClick={removeAction}
          />
        </span>
      )}
    </div>
  );
};
